import Routes from "./Routes";
import LocaleProvider, { useLocale } from "./locales";
import { CookiesProvider } from "react-cookie";
import { QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import ToastProvider from "store/ToastProvider";
import UserSettingProvider from "store/UserSettingsContext";
import { queryClient } from "utils/QueryClient";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { FacebookProvider } from "react-facebook";
import UserAuthProvider from "store/UserAuthContext";
import SocketProvider from "store/SocketConsumer";
import "react-datepicker/dist/react-datepicker.css";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import GA4React from "ga-4-react";

import "frappe-gantt/dist/frappe-gantt.css";
 
import React from "react";


// src/analytics.js
import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('G-375501447'); // Replace with your Google Analytics Measurement ID
};

export const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};


function App() {
  // console.log("facebookId", process.env.FACEBOOK_SECRET);
  
  React.useEffect(() => {
    const ga4react = new GA4React("G-375501447");
    ga4react.initialize().catch((err) => console.error("Analytics Failure.", err));
  }, []);
  


  return (
    <LocaleProvider>
      <CookiesProvider>
        <ToastContainer autoClose={2000} hideProgressBar closeButton={false} />
 
        <QueryClientProvider client={queryClient}>
          <ToastProvider>
            <GoogleOAuthProvider clientId="732238521483-pm04nusd6jcfe6uht3j1k8vis49m3oe0.apps.googleusercontent.com">
              <FacebookProvider appId={"257119866710066"}>
                <UserSettingProvider>
                  <UserAuthProvider>
                    <SocketProvider>
                      <Routes />
                    </SocketProvider>
                  </UserAuthProvider>
                </UserSettingProvider>
              </FacebookProvider>
            </GoogleOAuthProvider>
          </ToastProvider>
        </QueryClientProvider>
      </CookiesProvider>
    </LocaleProvider>
  );
}

 

export default App;
